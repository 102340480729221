import { extend } from "helper"

extend([ Array.prototype ],
{
    add: function()
    {
        return this.push(...arguments)
    },
    remove: function(item)
    {
        const i = this.indexOf(item)
        if (i != -1)
        {
            this.splice(i, 1)
            this.remove(item)
        }
    },
    random: function()
    {
        return this[Math.round(Math.random() * (this.length - 1))]
    }
})